import PropTypes from 'prop-types';
import React from 'react';

const SocialWelfare = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="34"
    viewBox="46 0 28 34"
  >
    <path
      fill={color}
      fillRule="evenodd"
      d="M70.45 22.67c0 .65-.45 1.14-1.06 1.14-.7 0-1.1-.5-1.1-1.1v-3.8c0-.6.4-1.1 1-1.1s1 .5 1 1.2v3.85zM49.13 29.5V15.65c2.75.84 6.18.94 7.68.94s5.2-.1 7.7-1v13.9c-.7.4-3.4 1.1-7.7 1.1-4.2 0-6.7-.7-7.6-1.2zm7.7-17.4c3.13 0 5.4.37 6.7.76-1.3.4-3.57.77-6.7.77-3.13 0-5.4-.38-6.68-.77 1.28-.4 3.55-.77 6.68-.77zm12.55 2.63c-.7 0-1.45.17-1.9.47v-2.47c0-.24-.1-.46-.2-.66-1.13-2.7-8.16-2.94-10.44-2.94-2.28 0-9.34.23-10.48 2.94-.1.2-.2.42-.2.66V30c0 .15.07.3.1.45.02.03.05.06.06.1 0 0 .02 0 .03.03 1 2.8 8.17 3.04 10.48 3.04 2.3 0 9.47-.24 10.48-3.04l.1-.04c0-.03.1-.06.1-.1.1-.14.1-.3.1-.45v-3.7c.5.3 1.28.4 1.9.4 2.3 0 4.1-1.8 4.1-4.1v-3.8c0-2.3-1.8-4.1-4.04-4.1zM58.44 8.15c-.33 0-.64-.16-.83-.45-.3-.46-.1-1.06.3-1.36.3-.2.8-.65.8-.94 0-.03 0-.12-.1-.3-.7-.72-1-1.85-.9-2.8.2-.82.7-1.47 1.5-1.83.5-.22 1.1 0 1.3.5.3.5 0 1.07-.4 1.3-.25.1-.3.26-.33.4-.06.3.1.8.36 1.05.7.7.78 1.42.7 1.9C60.56 6.98 59.2 7.9 59 8c-.16.1-.34.15-.52.15m-3.75 0c-.32 0-.64-.16-.83-.45-.3-.46-.16-1.06.3-1.36.3-.2.76-.65.8-.94 0-.03 0-.12-.17-.3-.73-.72-1.1-1.85-.9-2.8.14-.82.66-1.47 1.45-1.83.5-.22 1.08 0 1.3.5.23.5 0 1.07-.5 1.3-.24.1-.3.26-.32.4-.06.3.1.8.36 1.05.7.7.75 1.42.7 1.9-.14 1.36-1.5 2.28-1.67 2.38-.17.1-.35.15-.54.15"
    />
  </svg>
);

SocialWelfare.propTypes = {
  color: PropTypes.string,
};

SocialWelfare.defaultProps = {
  color: '#999999',
};

export default SocialWelfare;
