import PropTypes from 'prop-types';
import React from 'react';

const Education = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="38"
    height="37"
    viewBox="22 0 38 37"
  >
    <path
      fill={color}
      fillRule="evenodd"
      d="M41 3.5l12.85 4.85-6.08 2.3-5.83-3.5c-.74-.46-1.7-.22-2.15.53-.48.74-.24 1.7.5 2.16l3.7 2.22-3 1.14-12.86-4.85L41 3.5zm8.48 13.7c-.03.2-.38.54-1.05.9v-4.35l1.05-.4v3.86zm-8.6 2.36c-5.75 0-8.52-1.8-8.6-2.34h-.07v-3.98l8.17 3.08c.2.08.43.12.65.12.23 0 .45-.04.66-.12l3.64-1.38v4.2c-1.2.25-2.68.42-4.43.42zm6.5 13.64h-1.04v-1.8c0-.32.22-.56.52-.56.3 0 .53.24.53.56v1.8zm.06-6.52c0 .3-.25.55-.55.55-.3 0-.58-.25-.58-.55 0-.13.05-.25.13-.35.13.04.26.06.4.06.15 0 .3-.06.43-.1.1.1.13.22.13.36zM23.8 10.06l5.27 2v5.42h.08c.24 2.56 3.92 5.22 11.72 5.22 1.66 0 3.13-.13 4.42-.34v1c-1.26.6-2.1 1.85-2.1 3.32 0 .9.3 1.7.83 2.35-.53.65-.85 1.47-.85 2.37v4.95h7.32V31.4c0-.88-.3-1.7-.83-2.33.54-.65.87-1.48.87-2.4 0-1.48-.88-2.76-2.15-3.35v-1.77c2.67-1.02 4.02-2.56 4.16-4.07v-5.3l5.6-2.12c.7-.27 1.2-.94 1.2-1.72 0-.77-.5-1.44-1.2-1.7L41.64.37c-.42-.16-.9-.16-1.3 0L23.8 6.63c-.72.27-1.2.94-1.2 1.7 0 .8.48 1.46 1.2 1.73z"
    />
  </svg>
);

Education.propTypes = {
  color: PropTypes.string,
};

Education.defaultProps = {
  color: '#999999',
};

export default Education;
