import PropTypes from 'prop-types';
import React from 'react';

const LocalCommunity = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="30"
    viewBox="52 0 36 30"
  >
    <path
      fill={color}
      fillRule="evenodd"
      d="M71.98 26.62V18.3c0-1.2-1.14-2.16-2.32-2.16h-5.22c-1.03 0-1.88.85-1.88 1.88v8.6h-3.14V12.64l7.62-4.52 8.08 4.8v13.7h-3.14zm7.56-11.03c.25.1.5.2.76.2.54 0 1.04-.3 1.34-.8.44-.8.2-1.7-.56-2.2L70.15 6.3c.23-.03.46-.04.7 0H71l.37-.15.06-.26c.3-1.3 1.43-2.2 2.7-2.2.9 0 1.75.43 2.27 1.2l.15.14.24.1.1-.04.2-.04V5c.9-.9 2.42-1.1 3.5-.4 1.04.7 1.5 2.1 1.03 3.3l-.1.24.26.46.3.05c1.4.2 2.44 1.4 2.44 2.78 0 1.2-.77 2.26-1.87 2.63l-.25.05-.2.45.1.16c.18 1.08-.2 2.16-1.1 2.78-.9.67-2.52.66-3.04.05V14.8l1.3.8zm5.53-9.2C84.9 4.6 83.95 3 82.47 2c-1.57-1-3.6-1.2-5.37-.57-.9-.52-1.9-.8-2.94-.8-2.02 0-3.85 1-4.94 2.7-1.07.26-2.03.78-2.8 1.5l-.23.1L53 12.8c-.74.45-1 1.4-.54 2.16.44.74 1.42 1 2.16.54l1.66-.98v13.3c0 1.17.94 1.95 2.12 1.95h5.3c1.1 0 2-.72 2-1.84V19.3h3.14v8.63c0 1.12.72 1.84 1.83 1.84 0 0 7.25.18 8.1.18.88 0 1.58-.7 1.58-1.57v-7.74c0 .28 1.2.13 1.4.1 2.2-.32 3.42-2.62 3.72-4.62 1.43-1.12 2.3-2.84 2.3-4.67 0-2-1.02-3.88-2.7-4.97z"
    />
  </svg>
);

LocalCommunity.propTypes = {
  color: PropTypes.string,
};

LocalCommunity.defaultProps = {
  color: '#999999',
};

export default LocalCommunity;
