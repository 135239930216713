import PropTypes from 'prop-types';
import React from 'react';

const Animals = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="37"
    height="35"
    viewBox="43 0 37 35"
  >
    <path
      fill={color}
      fillRule="evenodd"
      d="M61.43 18.03c5.18 0 9.4 4.93 9.4 10.98 0 1.7-.32 2.1-.32 2.1s0 .03-.2.03c-.6 0-1.6-.32-2.7-.66-1.7-.54-3.8-1.2-6.1-1.2s-4.45.66-6.16 1.2c-1.07.34-2.1.66-2.7.66-.16 0-.22-.05-.22-.05 0-.02-.32-.37-.32-2 0-6.06 4.22-11 9.4-11m-12.54 11c0 1.67.25 2.85.8 3.7.45.67 1.3 1.47 2.9 1.47 1.1 0 2.25-.4 3.6-.8 1.6-.5 3.4-1.08 5.24-1.08 1.84 0 3.64.56 5.22 1.06 1.35.43 2.53.8 3.62.8 2.43 0 3.7-1.74 3.7-5.2 0-7.77-5.66-14.1-12.57-14.1-6.92 0-12.55 6.33-12.55 14.1zM55.3 3.6h.1c.8 0 1.9 1.26 2.18 3.1.16 1 .05 1.97-.3 2.7-.12.24-.45.83-.95.9h-.1c-.8 0-1.9-1.26-2.18-3.1-.3-1.9.44-3.5 1.26-3.63zm.9 9.9c.2 0 .4-.03.6-.06 1.4-.22 2.6-1.2 3.3-2.68.62-1.3.83-2.92.58-4.54C60.2 2.87 58 .44 55.45.44c-.2 0-.4 0-.58.04-2.74.42-4.44 3.6-3.9 7.22.52 3.34 2.73 5.77 5.28 5.77zm9.18-6.8c.3-1.84 1.38-3.1 2.18-3.1h.1c.83.1 1.56 1.73 1.27 3.63-.28 1.85-1.37 3.1-2.17 3.1h-.1c-.5-.06-.84-.65-.96-.9-.34-.74-.45-1.73-.3-2.72zm.8 6.74c.2.03.4.04.58.04 2.55 0 4.76-2.43 5.27-5.78.55-3.65-1.15-6.8-3.9-7.24-.18-.03-.37-.04-.57-.04C65 .42 62.8 2.85 62.3 6.2c-.24 1.63-.03 3.24.6 4.55.7 1.5 1.9 2.46 3.3 2.68zm9.7 1.15c-.44.8-1.03 1.1-1.27 1.1-.1-.16-.2-.85.3-1.67.44-.8 1.05-1.13 1.25-1.15.13.1.24.85-.2 1.7zm1.72-4.5c-.44-.25-.94-.37-1.46-.37-1.55 0-3.13 1.1-4.04 2.8-.55 1.02-.8 2.15-.68 3.18.12 1.25.75 2.25 1.7 2.77.46.22.96.35 1.48.35 1.55 0 3.14-1.1 4.04-2.8 1.26-2.35.8-4.96-1.03-5.94zm-30.73 2.8c.23 0 .84.35 1.27 1.15.46.86.35 1.7.28 1.7-.24 0-.85-.36-1.28-1.16-.45-.87-.34-1.6-.26-1.7zm1.54 5.95c.6 0 1.1-.13 1.5-.37 1-.6 1.6-1.6 1.7-2.8.1-1.07-.1-2.2-.7-3.2-.9-1.7-2.5-2.8-4-2.8-.5 0-1 .1-1.44.35-1.84 1-2.3 3.6-1.04 5.94.9 1.7 2.5 2.8 4.04 2.8"
    />
  </svg>
);

Animals.propTypes = {
  color: PropTypes.string,
};

Animals.defaultProps = {
  color: '#999999',
};

export default Animals;
