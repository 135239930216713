import PropTypes from 'prop-types';
import React from 'react';

const InternationalAid = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="37"
    viewBox="52 0 30 37"
  >
    <path
      fill={color}
      fillRule="evenodd"
      d="M69.62 32.37h-1.57v1.58h-1.57v-1.58H64.9V30.8h1.58v-1.57h1.57v1.57h1.57v1.57zM56.3 13.84c.4-.62 1.33-1 2.36-1 1.17 0 2.2.5 2.5 1.28h-.04l.1.27v.1h.04l1.9 5.6-6.87-6.37zm16.45.7h.03c0-.03 0-.07.02-.12l.2-.54h-.04c.37-.65 1.25-1.03 2.27-1.03 1.03 0 1.97.37 2.37 1l-6.82 6.22 1.97-5.52zm-5.8 6.95l-2.5-7.4c.26-.8 1.3-1.3 2.5-1.3 1.26 0 2.27.8 2.58 1.4l-2.6 7.2zm0-17.9c4.4 0 8.16 2.43 9.88 6.22-.5-.1-1.04-.2-1.6-.2-1.62 0-3.06.6-4.07 1.53-1.06-.9-2.56-1.5-4.2-1.5-1.64 0-3.1.6-4.16 1.5-1.04-.9-2.5-1.5-4.14-1.5-.55 0-1.1.06-1.6.2 1.72-3.8 5.5-6.28 9.9-6.28zm5.8 31.7v-7.9c0-.3-.23-.3-.52-.3H68l.2-.3h.18l.1-.38L81.13 15v-.7c0-7.72-6.4-14-14.13-14-7.72 0-14.13 6.3-14.13 14v.7l12.4 11.3.14.45.3.03.3.33h-3.7c-.3 0-.45.1-.45.4v7.9c0 .3.18.7.47.7h10c.3 0 .53-.4.53-.67z"
    />
  </svg>
);

InternationalAid.propTypes = {
  color: PropTypes.string,
};

InternationalAid.defaultProps = {
  color: '#999999',
};

export default InternationalAid;
