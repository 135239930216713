import PropTypes from 'prop-types';
import React from 'react';

const InMemory = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="36"
    viewBox="31 -1 26 36"
  >
    <g fill={color} fillRule="evenodd">
      <path d="M34.14 29.83v-3.57c2.77.86 6.6 1.04 9.36 1.04 2.77 0 7.05-.18 9.36-1.04v3.57c-.46.5-4.2 1.23-9.36 1.23-5.15 0-8.44-.73-9.36-1.23m9.7-7.02c3.68 0 6.38.4 7.97.8-1.5.4-4.2.8-7.9.8s-6.4-.4-8-.7c1.6-.4 4.3-.8 8-.8m-.3-2.9c-4.5 0-12.3.5-12.3 3.7v6.7c0 3.3 7.8 3.7 12.38 3.7S56 33.7 56 30.4v-6.7c0-3.2-7.8-3.7-12.34-3.7" />
      <path d="M44.2 23.6h-.98c-.53 0-.96-.6-.96-1.3v-4.6c0-.72.43-1.3.96-1.3h.97c.5 0 .9.58.9 1.3v4.6c0 .7-.5 1.3-1 1.3" />
      <path d="M43.6 10.48c.15 1.12.7 1.6 1.26 2.1.52.45 1.1.97 1.18 1.97.07 1.17-.7 2.2-1.8 2.44h-.35c-.7 0-1.3-.2-1.7-.6-.5-.4-.8-1-.8-1.6-.1-1.7.6-3.2.9-3.9l.1-.2c.2-.5.3-.8.7-.7 0 0 .3 0 .4.4" />
      <path d="M46.94 13.55c-.14.95-.64 1.8-1.4 2.35-.76.57-1.7.8-2.63.66-.9-.15-1.7-.66-2.3-1.43-.5-.77-.8-1.72-.6-2.67.4-2.5 1.9-5.1 3.3-7.04.5 1.83 1.5 3.13 2.3 4.26 1.1 1.52 1.7 2.4 1.5 3.87m.9-5.62C46.7 6.3 45.58 4.8 45.8 1.8v-.36c0-.8-.63-1.44-1.4-1.44-.5 0-.93.3-1.2.7-.2.22-.3.35-.46.55C41.2 3.1 37.77 7.5 37.07 12c-.26 1.74.16 3.46 1.17 4.87 1.02 1.4 2.52 2.32 4.2 2.6.35.04.68.07 1 .07 1.37 0 2.68-.44 3.8-1.27 1.4-1.03 2.3-2.55 2.55-4.27.4-2.77-.9-4.52-2-6.07" />
    </g>
  </svg>
);

InMemory.propTypes = {
  color: PropTypes.string,
};

InMemory.defaultProps = {
  color: '#999999',
};

export default InMemory;
