import PropTypes from 'prop-types';
import React from 'react';

const Disability = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="36"
    viewBox="20 0 35 36"
  >
    <path
      fill={color}
      fillRule="evenodd"
      d="M46.42 13.32c.48 0 .65.08.66.07.02 0 .1.2.1.7v2.3H32v-3.1h14.42zm4.96 16.43c0 1.5-1.23 2.73-2.73 2.73s-2.72-1.22-2.72-2.73c0-.96.5-1.8 1.25-2.28v2.62c0 .8.7 1.5 1.57 1.5.87 0 1.57-.7 1.57-1.6v-2.5c.64.5 1.06 1.3 1.06 2.13zm-21.33 2.73c-3.54 0-6.43-2.88-6.43-6.43 0-3.14 2.26-5.76 5.24-6.32v7.22c0 .87.7 1.57 1.57 1.57.87 0 1.57-.7 1.57-1.57v-7.03c2.6.83 4.47 3.26 4.47 6.13 0 3.55-2.88 6.43-6.42 6.43zm-1.2-15.93c-4.7.6-8.37 4.62-8.37 9.5 0 5.28 4.3 9.58 9.57 9.58 5.27 0 9.56-4.3 9.56-9.58 0-2.48-.9-4.74-2.5-6.44h10.1v4.5c-2.5.7-4.4 3-4.4 5.7 0 3.3 2.7 5.9 5.9 5.9 3.3 0 5.9-2.6 5.9-5.9 0-2.6-1.8-4.9-4.2-5.6v-10c0-3.24-2.1-3.93-3.9-3.93H32v-5.1c0-3.04-1.36-4.4-4.42-4.4h-3.96c-.86 0-1.57.7-1.57 1.57 0 .9.7 1.6 1.57 1.6h3.96c.92 0 1.13.16 1.13.16s.2.2.2 1.1v11.4z"
    />
  </svg>
);

Disability.propTypes = {
  color: PropTypes.string,
};

Disability.defaultProps = {
  color: '#999999',
};

export default Disability;
