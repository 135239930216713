import PropTypes from 'prop-types';
import React from 'react';
import CSSModules from 'react-css-modules';
import { Link } from 'react-router-dom';
import styles from './GetStarted.less';

const location = 'home > your story starts here';

const GetStarted = ({ getStarted }) => (
  <section styleName="get-started">
    <div styleName="overlay">
      <div styleName="content">
        <div styleName="header" className="dna-h1">
          Your story starts here
        </div>
        <div styleName="sub-header" className="dna-text-xl">
          Raise money for good
        </div>
        <div styleName="button-container">
          <Link
            styleName="button"
            className="dna-button dna-button-brand"
            to="/create"
            onClick={() => getStarted({ location })}
          >
            Get started
          </Link>
        </div>
      </div>
    </div>
  </section>
);

GetStarted.propTypes = {
  getStarted: PropTypes.func.isRequired,
};

export default CSSModules(GetStarted, styles, {
  allowMultiple: true,
});
