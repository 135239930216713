import React from 'react';
import CSSModules from 'react-css-modules';
import config from 'config';
import ProcessingCostStrings from 'src/components/Funds/ProcessingCosts/ProcessingCostStrings';
import styles from './Fees.less';

const Fees = () => (
  <section styleName="container">
    <div className="dna-grid-container">
      <div className="dna-grid-row" styleName="body">
        <h1 className="dna-h1" styleName="title">
          Easy, secure, affordable
        </h1>
        <p className="dna-text-xl">
          Join <span className="dna-text-normal">500,000+</span> people raising
          money for personal causes on JustGiving
        </p>
        <div styleName="benefits">
          <ul styleName="checklist">
            <li>
              <p
                className="dna-text-xl dna-text-normal"
                styleName="checklist__item"
              >
                Raise money with the world's most trusted fundraising website
              </p>
            </li>
            <li>
              <p
                className="dna-text-xl dna-text-normal"
                styleName="checklist__item"
              >
                No platform fee - keep more of what you raise!
              </p>
            </li>
            <li>
              <p
                className="dna-text-xl dna-text-normal"
                styleName="checklist__item"
              >
                Withdraw what you've raised to your bank account
              </p>
            </li>
          </ul>
          <ul styleName="list">
            <li>
              <ProcessingCostStrings targetCurrency="GBP">
                {({ perDonationBoth }) => (
                  <p className="dna-text-xl">
                    * Standard processing costs apply ({perDonationBoth})
                  </p>
                )}
              </ProcessingCostStrings>
            </li>
            <li>
              <p>
                <a
                  className="dna-text-xl dna-text-normal"
                  target="_blank"
                  rel="noreferrer noopener"
                  href=" https://help.justgiving.com/hc/en-us/articles/203067602-Crowdfunding-Fees"
                  styleName="link"
                >
                  Read more about our fees
                </a>
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
);

export default CSSModules(Fees, styles);
