import PropTypes from 'prop-types';
import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import { formatCurrency } from 'src/helpers/formatter';
import styles from './Testimonial.less';

class Testimonial extends Component {
  static propTypes = {
    direction: PropTypes.oneOf(['right', 'left']).isRequired,
    image: PropTypes.string.isRequired,
    quote: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    amountRaised: PropTypes.number.isRequired,
    targetCurrency: PropTypes.string.isRequired,
    totalCountSupporters: PropTypes.number.isRequired,
  };

  renderBubble() {
    const {
      direction,
      quote,
      name,
      amountRaised,
      targetCurrency,
      totalCountSupporters,
    } = this.props;

    const formattedAmount = formatCurrency(amountRaised, {
      currency: targetCurrency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

    return (
      <div styleName={`speech-bubble-${direction}`}>
        <div className="dna-text-l" styleName="quote">
          &quot;
          {quote}
          &quot;
        </div>
        <div className="dna-text-default-regular" styleName="name">
          {name}
        </div>
        <div className="dna-show-block-from-l dna-text-l">
          <span>Raised </span>
          <span className="dna-text-brand-m">{formattedAmount}</span>
          <span> with </span>
          <span className="dna-h5">
            {totalCountSupporters} supporter
            {totalCountSupporters !== 1 ? 's' : ''}
          </span>
        </div>
      </div>
    );
  }

  renderAvatar() {
    const { image, name } = this.props;

    return (
      <div styleName="avatar-container">
        <div
          styleName="avatar"
          style={{ backgroundImage: `url(${image})` }}
          alt={`${name} profile image`}
        />
      </div>
    );
  }

  renderReverseAvatar() {
    return <div styleName="avatar-reverse-display">{this.renderAvatar()}</div>;
  }

  render() {
    const { direction } = this.props;

    return (
      <div styleName={`container-${direction}`}>
        {direction === 'left' && this.renderAvatar()}
        {this.renderBubble()}
        {direction === 'right' && this.renderReverseAvatar()}
      </div>
    );
  }
}

export default CSSModules(Testimonial, styles);
