import PropTypes from 'prop-types';
import React from 'react';

const Lgbt = ({ color }) => (
  <svg
    width="24px"
    height="33px"
    viewBox="4 0 24 33"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="icon-lgbtq-med-grey"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      transform="translate(4.000000, 0.000000)"
    >
      <g id="Page-1" fill={color} fillRule="evenodd">
        <g id="Icons-med-(26px)">
          <g id="icon-/-lgbtq-/-med-/-grey">
            <path
              d="M9.41389339,20.1774517 C6.40048552,20.1774517 3.94881608,17.7146403 3.94881608,14.6865299 C3.94881608,11.6586345 6.40048552,9.19517816 9.41389339,9.19517816 C12.4273013,9.19517816 14.8785428,11.6586345 14.8785428,14.6865299 C14.8785428,17.7146403 12.4273013,20.1774517 9.41389339,20.1774517 M22.6498281,0.916666666 L15.9425516,0.916666666 C15.6841434,0.916666666 15.4745075,1.12731034 15.4745075,1.38696092 L15.4745075,3.26813793 C15.4745075,3.5277885 15.6841434,3.73843219 15.9425516,3.73843219 L18.3240572,3.73843219 L14.1792577,7.90294368 C12.8305293,6.94322528 11.1885248,6.37362759 9.41389339,6.37362759 C4.85196139,6.37362759 1.14055184,10.1026655 1.14055184,14.6865299 C1.14055184,18.7882781 4.11566908,22.1970517 8.00976126,22.8700368 L8.00976126,26.1831609 L4.96854457,26.1831609 C4.71013635,26.1831609 4.50050053,26.3938046 4.50050053,26.6534552 L4.50050053,28.5346322 C4.50050053,28.7942828 4.71013635,29.0049265 4.96854457,29.0049265 L8.00976126,29.0049265 L8.00976126,31.6130391 C8.00976126,31.8726897 8.21939709,32.0833334 8.47780535,32.0833334 L10.3499814,32.0833334 C10.6083897,32.0833334 10.8180255,31.8726897 10.8180255,31.6130391 L10.8180255,29.0049265 L13.9533644,29.0049265 C14.2119865,29.0049265 14.4214085,28.7942828 14.4214085,28.5346322 L14.4214085,26.6534552 C14.4214085,26.3938046 14.2119865,26.1831609 13.9533644,26.1831609 L10.8180255,26.1831609 L10.8180255,22.8700368 C14.7116899,22.1970517 17.6865932,18.7882781 17.6865932,14.6865299 C17.6865932,12.9031517 17.1197208,11.2532529 16.1645944,9.89782529 L20.3098218,5.73309885 L20.3098218,8.12648391 C20.3098218,8.38634943 20.5192436,8.59677816 20.7778659,8.59677816 L22.6498281,8.59677816 C22.9084502,8.59677816 23.117872,8.38634943 23.117872,8.12648391 L23.117872,1.38696092 C23.117872,1.12731034 22.9084502,0.916666666 22.6498281,0.916666666"
              id="Fill-1"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

Lgbt.propTypes = {
  color: PropTypes.string,
};

Lgbt.defaultProps = {
  color: '#999999',
};

export default Lgbt;
