import React from 'react';
import Alert from 'src/components/Alert/';

type Props = {
  onClose: () => void;
};

const DeletePageSuccessAlert: React.StatelessComponent<Props> = ({
  onClose,
}) => {
  return (
    <Alert
      id="delete_page_success"
      type="green"
      autoCloseMs={5000}
      onClose={onClose}
    >
      <p className="jg-space-mbn">Your Page has been deleted.</p>
    </Alert>
  );
};

export default DeletePageSuccessAlert;
