import { PROCESSING_COSTS } from 'config';
import { currencyMapToPenny } from 'src/helpers/currencyMap';

interface Props {
  children: (props: ProcessingCostStringsReturnProps) => JSX.Element;
  targetCurrency: Currency;
}

export interface ProcessingCostStringsReturnProps {
  fixed: string;
  perDonationBoth: string;
  percentage: string;
  percentageAndFixed: string;
  plusPerDonationFixed: string;
}

const ProcessingCostStrings = ({ children, targetCurrency }: Props) => {
  const processingCosts =
    PROCESSING_COSTS[targetCurrency] || PROCESSING_COSTS.GBP;

  const fixedCosts = `${processingCosts.FIXED * 100}${currencyMapToPenny[
    targetCurrency
  ] || currencyMapToPenny.GBP}`;

  const percentageCosts = `${processingCosts.PERCENTAGE}%`;

  const costs = {
    fixed: fixedCosts,
    percentage: percentageCosts,
    percentageAndFixed: percentageCosts + fixedCosts,
    perDonationBoth: `${percentageCosts} + ${fixedCosts} per donation`,
    plusPerDonationFixed: `+ ${fixedCosts} per donation`,
  };

  return children(costs);
};

export default ProcessingCostStrings;
