import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './Carousel.scss';
import tipStyles from './TipCarousel.scss';
import placeholderStyles from './PlaceholderCarousel.scss';

export interface OnSlideOptions {
  direction?: 'left' | 'right';
  location: string;
  currentIndex: number;
  nextIndex: number;
  event?: TouchEvent | React.SyntheticEvent<HTMLElement>;
}

export interface Props {
  defaultSlide?: number;
  autoRotate?: number;
  touchable: boolean;
  translationRatio?: number;
  disableVerticalTouchMove: boolean;
  location: string;
  hideButtons?: boolean;
  dynamicWidth: boolean;
  ref?: (c: any) => void;
  slideCarousel(options: OnSlideOptions): void;
}

interface State {
  currentSlide: number;
  touches: {
    touchstart: { x: number; y: number };
    touchmove: { x: number; y: number };
    distance: { x: number; y: number };
    touchend: boolean;
    direction: 'left' | 'right' | null;
  };
}

export type CarouselType = Carousel;

class Carousel extends React.Component<Props, State> {
  static defaultProps = {
    translationRatio: -1,
    disableVerticalTouchMove: false,
    slideCarousel: () => {},
    hideButtons: false,
    dynamicWidth: false,
  };

  track: HTMLUListElement;
  carousel: HTMLDivElement;
  container: HTMLDivElement;
  rotateInterval: number;

  constructor(props: Props) {
    super(props);

    this.state = {
      currentSlide: this.props.defaultSlide || 0,
      touches: {
        touchstart: {
          x: -1,
          y: -1,
        },
        touchmove: {
          x: -1,
          y: -1,
        },
        distance: {
          x: -1,
          y: -1,
        },
        touchend: true,
        direction: null,
      },
    };
  }

  componentDidMount() {
    const { touchable, autoRotate } = this.props;

    if (touchable) {
      this.track.addEventListener('touchstart', this.handleTouch, false);
      this.track.addEventListener('touchmove', this.handleTouch, false);
      this.track.addEventListener('touchend', this.handleTouch, false);
    }

    if (autoRotate) {
      this.container.addEventListener('mouseenter', this.pause, false);
      this.container.addEventListener('mouseleave', this.resume, false);

      this.resume();
    }
  }

  componentWillUnmount() {
    const { touchable, autoRotate } = this.props;

    if (touchable) {
      this.track.removeEventListener('touchstart', this.handleTouch);
      this.track.removeEventListener('touchmove', this.handleTouch);
      this.track.removeEventListener('touchend', this.handleTouch);
    }

    if (autoRotate) {
      this.container.removeEventListener('mouseenter', this.pause);
      this.container.removeEventListener('mouseleave', this.resume);

      this.pause();
    }
  }

  setIndex(
    index: number,
    event?: TouchEvent | React.SyntheticEvent<HTMLElement>
  ) {
    const { currentSlide } = this.state;
    const { slideCarousel, location } = this.props;
    const direction = index > currentSlide ? 'right' : 'left';

    this.setState({ currentSlide: index });

    slideCarousel({
      direction,
      location,
      currentIndex: currentSlide,
      nextIndex: index,
      event,
    });
  }

  pause = () => {
    if (this.rotateInterval) {
      clearInterval(this.rotateInterval);
    }
  };

  resume = () => {
    const { autoRotate } = this.props;

    if (autoRotate! > 0) {
      this.pause();
      this.rotateInterval = window.setInterval(() => {
        const { children } = this.props;
        let { currentSlide } = this.state;

        if (currentSlide === React.Children.count(children) - 1) {
          currentSlide = 0;
        } else {
          currentSlide += 1;
        }

        this.setIndex(currentSlide);
      }, autoRotate);
    }
  };

  handlePrevious = (event?: React.SyntheticEvent<HTMLElement>) => {
    const { currentSlide } = this.state;

    if (currentSlide === 0) {
      return;
    }

    this.setIndex(currentSlide - 1 || 0, event);
  };

  handleNext = (event?: React.SyntheticEvent<HTMLElement>) => {
    const { currentSlide } = this.state;
    const { children } = this.props;

    if (currentSlide === React.Children.count(children) - 1) {
      return;
    }

    this.setIndex(currentSlide + 1, event);
  };

  handleDot = (index: number) => (event: React.SyntheticEvent<HTMLElement>) => {
    const { children } = this.props;

    if (index > React.Children.count(children) - 1) {
      return;
    }

    if (index < 0) {
      return;
    }

    this.setIndex(index, event);
  };

  handleTouch = (e: TouchEvent) => {
    this.pause();

    const { disableVerticalTouchMove } = this.props;
    const touch = e.touches[0];

    switch (e.type) {
      case 'touchstart':
        this.setState({
          ...this.state,
          touches: {
            ...this.state.touches,
            [e.type]: {
              x: touch.pageX,
              y: touch.pageY,
            },
            touchmove: {
              x: touch.pageX,
              y: touch.pageY,
            },
            touchend: false,
          },
        });
        break;
      case 'touchmove':
        this.setState({
          ...this.state,
          touches: {
            ...this.state.touches,
            [e.type]: {
              x: touch.pageX,
              y: touch.pageY,
            },
            touchend: false,
          },
        });
        break;
      case 'touchend': {
        if (
          this.state.touches.touchstart.x > -1 &&
          this.state.touches.touchmove.x > -1
        ) {
          this.setState({
            ...this.state,
            currentSlide: this.moveSlide(e),
            touches: {
              ...this.state.touches,
              direction:
                this.state.touches.touchstart.x < this.state.touches.touchmove.x
                  ? 'right'
                  : 'left',
              distance: {
                x: Math.abs(this.state.touches.touchstart.x),
                y: Math.abs(this.state.touches.touchstart.y),
              },
              [e.type]: true,
            },
          });
        }
        break;
      }
      default:
        break;
    }

    if (disableVerticalTouchMove) {
      this.preventScrolling(e);
    }
  };

  preventScrolling(e: TouchEvent) {
    const {
      touches: { touchmove, touchstart },
    } = this.state;
    const interactions = ['a', 'button'];
    const isInteraction = interactions.includes(
      (e.target as HTMLElement).tagName.toLowerCase()
    );
    const movement = Math.abs(touchmove.y - touchstart.y);

    if (!isInteraction && movement > 0) {
      e.preventDefault();
    }

    if (isInteraction && movement > 10) {
      e.preventDefault();
    }
  }

  moveSlide(event: TouchEvent) {
    const { children, slideCarousel, location } = this.props;
    const {
      currentSlide,
      touches: { touchstart, touchmove },
    } = this.state;
    const slideWidth = document.body.offsetWidth;
    const start = currentSlide * slideWidth;
    const movement = start + (touchstart.x - touchmove.x);

    if (Math.abs(currentSlide * slideWidth - movement) > slideWidth / 6) {
      if (
        movement > start &&
        currentSlide < React.Children.count(children) - 1
      ) {
        slideCarousel({
          direction: 'right',
          location,
          currentIndex: currentSlide,
          nextIndex: currentSlide + 1,
          event,
        });
        return currentSlide + 1;
      } else if (movement < start && currentSlide > 0) {
        slideCarousel({
          direction: 'left',
          location,
          currentIndex: currentSlide,
          nextIndex: currentSlide - 1,
          event,
        });
        return currentSlide - 1;
      }
    }

    return currentSlide;
  }

  calculateSlideWidth(children: React.ReactNode) {
    return {
      width: `${
        React.Children.count(children) > 1
          ? 100 / React.Children.count(children)
          : 100
      }%`,
    };
  }

  formatChildren(children: React.ReactNode) {
    const slideStyle = !this.props.dynamicWidth
      ? {}
      : this.calculateSlideWidth(children);

    return React.Children.map(children, (child: any, index) => {
      const props = {
        isVisible: this.state.currentSlide === index,
      };

      const slide = React.cloneElement(child, props);

      return (
        <li key={index} styleName="slide" style={slideStyle}>
          {slide}
        </li>
      );
    });
  }

  isFirst() {
    const { currentSlide } = this.state;

    return currentSlide === 0;
  }

  isLast() {
    const { currentSlide } = this.state;
    const { children } = this.props;

    return currentSlide === React.Children.count(children) - 1;
  }

  transform() {
    const { children, translationRatio } = this.props;
    const {
      currentSlide,
      touches: { touchend, touchstart, touchmove },
    } = this.state;

    if (__SERVER__) {
      return 'translateX(0%)';
    }

    if (touchend === false && touchstart.x > 0) {
      let movement = touchstart.x - touchmove.x;
      const slideWidth = document.body.offsetWidth;
      const start = currentSlide * slideWidth;
      const limit = slideWidth / 32;

      if (currentSlide === 0 && movement < limit * -1) {
        movement = limit * -1;
      }

      if (currentSlide === React.Children.count(children) - 1) {
        if (movement > limit) {
          movement = limit * -1;
        }

        if (movement > 0) {
          movement *= -1;
        }
      }

      const position = translationRatio! * Math.abs(start + movement);

      return `translateX(${position}px)`;
    }

    return `translateX(${(100 / React.Children.count(children)) *
      currentSlide *
      translationRatio!}%)`;
  }

  render() {
    const { children, hideButtons } = this.props;

    if (!children) {
      return null;
    }

    const { currentSlide, touches } = this.state;
    const track = {
      width: `${100 * React.Children.count(children)}%`,
      transform: this.transform(),
      transition:
        touches.touchend === true ? 'transform .3s ease-out' : 'initial',
    };

    return (
      <div
        styleName="container"
        ref={c => {
          this.container = c as HTMLDivElement;
        }}
      >
        <div styleName="body">
          {!hideButtons && (
            <div styleName="previous">
              <button
                type="button"
                onClick={this.handlePrevious}
                disabled={this.isFirst()}
                data-jqa-event-value="arrow previous"
              >
                <span styleName="sr-only">Previous</span>
              </button>
            </div>
          )}
          <div
            styleName="carousel"
            ref={c => {
              this.carousel = c as HTMLDivElement;
            }}
          >
            <ul styleName="dots" className="jg-space-mbn">
              {React.Children.map(children, (_, i) => (
                <li key={i}>
                  <button
                    styleName={i === currentSlide ? 'dot-active' : 'dot'}
                    type="button"
                    onClick={this.handleDot(i)}
                    data-jqa-event-value="carousel dot"
                  >
                    <span styleName="sr-only">Page {i + 1}</span>
                  </button>
                </li>
              ))}
            </ul>
            <ul
              style={track}
              ref={c => {
                this.track = c as HTMLUListElement;
              }}
            >
              {this.formatChildren(children)}
            </ul>
          </div>
          {!hideButtons && (
            <div styleName="next">
              <button
                type="button"
                onClick={this.handleNext}
                disabled={this.isLast()}
                data-jqa-event-value="arrow next"
              >
                <span styleName="sr-only">Next</span>
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

Carousel.defaultProps = {
  translationRatio: -1,
  disableVerticalTouchMove: false,
  slideCarousel: () => {},
  hideButtons: false,
  dynamicWidth: false,
};

export default CSSModules(Carousel, styles);

export const TipCarousel = CSSModules(
  Carousel,
  { ...styles, ...tipStyles },
  {}
);

// The below is a temporary measure to facilitate the release of Coach, specifically the funds section. It should be deleted once the full Coach workflow is available.

export const PlaceholderCarousel = CSSModules(
  Carousel,
  {
    ...styles,
    ...placeholderStyles,
  },
  {}
);
