import PropTypes from 'prop-types';
import React from 'react';

const Health = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="34"
    height="32"
    viewBox="52 0 34 32"
  >
    <path
      fill={color}
      fillRule="evenodd"
      d="M72.83 31.1c-.68 0-1.28-.44-1.46-1.1L68 17.6h-2.82c-.62 0-1.18-.4-1.4-.98l-2.26-5.8-2.45 5.84c-.23.56-.78.94-1.4.94H53.9c-.84 0-1.52-.66-1.52-1.5 0-.83.68-1.48 1.52-1.48h2.77l3.5-8.4c.25-.58.8-.97 1.43-.95.6 0 1.16.4 1.38.98l3.24 8.37h2.93c.68 0 1.28.42 1.46 1.08l1.75 6.33 2.7-19.95c.1-.7.7-1.26 1.4-1.3.73-.05 1.37.45 1.56 1.14l3.37 12.7h2.74c.85 0 1.53.65 1.53 1.5 0 .82-.68 1.48-1.52 1.48h-3.9c-.7 0-1.3-.47-1.45-1.14l-1.72-6.48-2.7 19.8c-.1.73-.67 1.27-1.4 1.32h-.1"
    />
  </svg>
);

Health.propTypes = {
  color: PropTypes.string,
};

Health.defaultProps = {
  color: '#999999',
};

export default Health;
