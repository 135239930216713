import PropTypes from 'prop-types';
import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import config from 'config';
import styles from './Hero.less';

class Hero extends Component {
  static propTypes = {
    isVisible: PropTypes.bool,
    image: PropTypes.string.isRequired,
    statement: PropTypes.string.isRequired,
    pageName: PropTypes.string.isRequired,
  };

  // This is used to enable entities for design to stop hanging fragments on statement lines
  static createStatement(statement) {
    return { __html: statement };
  }

  componentDidMount() {
    const { isVisible } = this.props;

    if (isVisible) {
      setImmediate(() => {
        this.active.classList.add('show');
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { isVisible } = this.props;

    if (isVisible !== nextProps.isVisible) {
      if (nextProps.isVisible) {
        this.active.classList.add('show');
      } else {
        this.active.classList.remove('show');
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.next);
  }

  render() {
    const { image, statement, pageName } = this.props;

    return (
      <div styleName={image}>
        <h1 styleName="brand" className="jg-space-mbn">
          JustGiving Crowdfunding
        </h1>
        <div
          styleName="active"
          ref={c => {
            this.active = c;
          }}
        >
          <p
            styleName="statement"
            className="jg-space-mbn"
            dangerouslySetInnerHTML={Hero.createStatement(statement)}
          />
          <p styleName="action" className="jg-space-mbn">
            <a
              href={`${config.CROWDFUNDING_BASE_URI}/${pageName}`}
              className="dna-button dna-button-ghost"
            >
              Read our story
            </a>
          </p>
        </div>
      </div>
    );
  }
}

export default CSSModules(Hero, styles);
