import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import PropTypes from 'prop-types';
import Collapse from 'rc-collapse';
import throttle from 'lodash/throttle';
import config from 'config';
import { isDesktopViewport } from '../../helpers/browser';
import styles from './Faq.less';
import FaqQuestion from './FaqQuestion';

const questions = [
  {
    text: 'What can I raise money for?',
    firstParagraph:
      'In short, anything that has a positive impact on individuals, communities, and the environment.',
    secondParagraph:
      'Most people use JustGiving Crowdfunding to help a friend in need. Not sure if itʼs for you?',
    link: {
      href: 'https://help.justgiving.com/hc/en-us/articles/202895531',
      value: 'Read our guidelines',
    },
  },
  {
    text: 'What happens if I donʼt hit my target?',
    firstParagraph:
      'If you donʼt hit your target youʼll still receive any funds you raised (minus some small fees), so you can still make good things happen.',
    secondParagraph:
      'Our data shows that pages with targets raise more than those without, so itʼs good to have something to aim for.',
  },
  {
    text: 'Can I change my page duration?',
    firstParagraph: `A shorter time limit keeps up the momentum and thatʼs why pages have an initial duration of ${
      config.DEFAULT_PAGE_LIFE
    } days.`,
    secondParagraph: `Once your page is active you can extend it up to 1 year if you prefer.`,
  },
];

class Faq extends Component {
  static propTypes = {
    seeMore: PropTypes.func.isRequired,
  };

  static renderColumns() {
    return questions.map((question, index) => (
      <div key={index} styleName="question">
        <h4 className="dna-h4">{question.text}</h4>
        <FaqQuestion
          firstParagraph={question.firstParagraph}
          secondParagraph={question.secondParagraph}
          link={question.link}
        />
      </div>
    ));
  }

  static renderAccordion() {
    return (
      <Collapse accordion defaultActiveKey=".$0">
        {/* Children key clashes with Panel key property hence the .$0  */}
        {questions.map((question, index) => (
          <Collapse.Panel key={index} header={question.text}>
            <FaqQuestion
              firstParagraph={question.firstParagraph}
              secondParagraph={question.secondParagraph}
              link={question.link}
            />
          </Collapse.Panel>
        ))}
      </Collapse>
    );
  }

  state = { isDesktop: isDesktopViewport() };

  componentDidMount() {
    if (__CLIENT__) {
      this.resizeHandler = throttle(this.onResize, 60);
      window.addEventListener('resize', this.resizeHandler);
    }
  }

  componentWillUnmount() {
    if (__CLIENT__) {
      window.removeEventListener('resize', this.resizeHandler);
    }
  }

  onResize = () => {
    this.setState({ isDesktop: isDesktopViewport() });
  };

  render() {
    const { isDesktop } = this.state;
    const { seeMore } = this.props;

    return (
      <section styleName="container">
        <div className="dna-grid-container">
          <div className="dna-grid-row" styleName="body">
            <h1 className="dna-h1" styleName="title">
              Frequently asked questions
            </h1>
            <div styleName="content">
              {isDesktop ? Faq.renderColumns() : Faq.renderAccordion()}
            </div>
            <div styleName="action">
              <a
                className="dna-button dna-button-ghost"
                target="_blank"
                rel="noreferrer noopener"
                href="https://help.justgiving.com/hc/en-us/categories/201602465-Crowdfunding"
                styleName="link"
                onClick={seeMore}
              >
                See more
              </a>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default CSSModules(Faq, styles);
