import PropTypes from 'prop-types';
import React from 'react';

const Art = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="38"
    height="38"
    viewBox="32 0 38 38"
  >
    <g fill={color} fillRule="evenodd">
      <path d="M65.35 23.24c-.12 1.98-1.5 3.96-2.5 5.28-2.18 2.8-5.47 4.48-9.27 4.73-3.57.24-7.2-.8-10.3-2.96 1.37-1.2 2.95-2.6 4.8-4.3 4.53-4.1 9.04-8.3 11.1-10.2.86 1.8 2.15 2.7 4.27 3.8 1.23.6 2.03 1.5 1.9 3.5M38.55 9.7c4.6-5.95 12.32-6.37 16.83-3.35.64.42 1.13.98 1.52 1.73-.83.13-1.6.5-2.2 1.1-.02.04-.07.1-.13.15-.26-.38-.57-.7-.94-1-.75-.6-1.68-.9-2.62-.9-1.2 0-2.3.54-3.1 1.48-.6.9-.9 2-.7 3.1.2 1.1.8 2 1.6 2.7.2.2.4.3.6.4l-5.7 6.3c-.8 0-1.5.4-2.1 1-.4.6-.6 1.3-.5 1.9l-1.8 2.1c-3.8-5.1-4.2-11.9-.6-16.6m22.6-2.7c.3-.2 1.1-.6 2.5-1.3-.6 1.4-1.1 2.2-1.3 2.5-.2.2-.4.3-.5.3-.2 0-.4-.02-.6-.22-.2-.2-.26-.44-.26-.56 0-.2 0-.4.2-.6m-9.9 5.3c-.23 0-.5-.1-.7-.3-.22-.2-.4-.5-.42-.7 0-.2 0-.4.15-.6.2-.27.47-.3.6-.3.26 0 .5.1.7.2.26.2.4.4.46.7 0 .1 0 .3-.06.43l-.3.3c-.2.07-.3.1-.4.1m-5.1 10.6c2.8-3.13 5.6-6.14 7.6-8.3l.6-.63v-.1c1.34-1.44 2.2-2.38 2.36-2.5.2-.2.44-.25.57-.25.1 0 .36.03.56.23.3.3.3.83 0 1.13-.35.38-5.76 5.47-11.5 10.67l-2.3 2.1 2.1-2.3m18.6-6.05c-2-1.1-2.7-1.64-3.24-3.25-.1-.3-.2-.67-.3-1.13 0-.3.02-.63 0-.94h.4c1 0 2-.4 2.73-1.13.7-.7 1.98-3.1 3.6-7 .2-.5.2-1.08-.1-1.54-.3-.5-.8-.76-1.4-.76h-.3l-.3.14c-3.9 1.7-6.3 2.9-7 3.66l-.3.3c-.4-.5-.95-1-1.6-1.44-7.23-4.9-16.53-1.74-21 4-4.64 6-4 14.6 1 20.95l-1.24 1.5c-2.8 3.3-4.16 4.95-2.8 6.28.3.3.7.5 1.2.5.6 0 1.3 0 6.6-4.6 3.5 2.6 7.6 4.04 11.76 4.04.4 0 .8 0 1.2-.06 4.7-.3 8.8-2.4 11.5-5.95 1.3-1.64 3-4.2 3.2-7 .2-3-1-5.2-3.6-6.55" />
      <path d="M43.96 18.56c.9-1.15.68-2.8-.47-3.7-1.2-.88-2.8-.67-3.7.48-.9 1.15-.7 2.8.4 3.7 1.1.88 2.8.67 3.7-.48m8.4 6.62c-1.2-.9-2.8-.68-3.7.47-.9 1.15-.7 2.8.4 3.7 1.1.88 2.8.67 3.7-.48.8-1.15.6-2.8-.5-3.7m7.3-2.45c-1.2-.9-2.8-.68-3.7.47-.9 1.1-.7 2.8.5 3.6 1.1.9 2.8.7 3.7-.5.9-1.2.6-2.8-.5-3.7" />
    </g>
  </svg>
);

Art.propTypes = {
  color: PropTypes.string,
};

Art.defaultProps = {
  color: '#999999',
};

export default Art;
