import PropTypes from 'prop-types';
import React from 'react';

const Gardens = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="34"
    height="34"
    viewBox="34 0 34 34"
  >
    <path
      fill={color}
      fillRule="evenodd"
      d="M48.8 28.8l8.86-3.34L62.2 30c-1.62.43-3.3.65-5 .65-2.96 0-5.8-.64-8.4-1.85zm-7.15-5.68l9.87-3.8 4.6 4.6-9.6 3.62c-1.2-.77-2.33-1.67-3.36-2.7-.54-.55-1.04-1.12-1.5-1.72zm6.5-19.48l-2.6 6.9-6.38-6.37c1.83-.54 3.72-.82 5.64-.82 1.15 0 2.26.1 3.35.3zm-1.08 8.43l3.03-8c1.9.52 3.7 1.32 5.36 2.38l-3.78 10.23-4.6-4.6zM45.5 13.3l4.5 4.5-9.54 3.66c-1.07-1.67-1.86-3.48-2.38-5.37l7.4-2.8zM38 5.8l5.96 5.95-6.32 2.4c-.47-2.75-.37-5.6.35-8.36zm23.5 6.7l-3.8 10.2-4.48-4.48 3.9-10.58c.6.47 1.17.98 1.72 1.52 1.02 1.02 1.9 2.14 2.67 3.33zm2.32 16.32l-4.58-4.58 3.53-9.47c2.04 4.35 2.45 9.34 1.05 14.05zM60.94 7.06C56.48 2.6 50.64.38 44.8.38c-2.8 0-5.63.52-8.3 1.56-.25.1-.46.3-.56.57-3.2 8.2-1.48 17.84 5.12 24.45 4.46 4.45 10.3 6.68 16.13 6.68 2.8 0 5.62-.52 8.3-1.56.24-.1.45-.3.55-.57 3.2-8.2 1.48-17.83-5.12-24.44z"
    />
  </svg>
);

Gardens.propTypes = {
  color: PropTypes.string,
};

Gardens.defaultProps = {
  color: '#999999',
};

export default Gardens;
