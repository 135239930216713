const CATEGORY_PAGE_REQUEST = 'CATEGORY_PAGE_REQUEST';
const GET_STARTED_REQUEST = 'GET_STARTED_REQUEST';
const SEE_MORE_FAQ_REQUEST = 'SEE_MORE_FAQ_REQUEST';
const HOME_PAGE_CARD_SELECTED = 'HOME_PAGE_CARD_SELECTED';

export const loadCategoryPage = ({ name }: { name: string }) => ({
  type: CATEGORY_PAGE_REQUEST,
  meta: {
    analytics: {
      event: 'link click',
      subtype: 'button',
      event_value: name.toLowerCase(),
      page_section: 'home',
    },
  },
});

export const getStarted = ({ location }: { location: string }) => ({
  type: GET_STARTED_REQUEST,
  meta: {
    analytics: {
      event: 'link click',
      subtype: 'button',
      event_value: 'get started',
      page_section: location,
    },
  },
});

export const seeMoreFaq = () => ({
  type: SEE_MORE_FAQ_REQUEST,
  meta: {
    analytics: {
      event: 'link click',
      subtype: 'button',
      event_value: 'see more',
      page_section: 'home > faqs',
    },
  },
});

interface SelectCardParams {
  id: string;
  type: string;
  url: string;
  rank: string;
  totalCount: string;
}

export const selectCard = ({
  id,
  type,
  url,
  rank,
  totalCount,
}: SelectCardParams) => ({
  type: HOME_PAGE_CARD_SELECTED,
  meta: {
    analytics: {
      event: 'click',
      subtype: 'card',
      event_value: 'crowdfunding card',
      page_section: 'home > show me',
      activity_id: id,
      activity_type: 'crowdfunding_guid',
      content_id: id,
      content_type: 'crowdfunding_guid',
      card_id: `${type}-${id}`,
      card_url: url,
      card_rank: rank,
      card_count: totalCount,
    },
  },
});
