import PropTypes from 'prop-types';
import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './Home.less';
import Categories from './Categories';
import Fees from './Fees';
import GetStarted from './GetStarted';
import Faq from './Faq';
import Heroes from './Heroes';
import Testimonials from './Testimonials';

const Home = ({ loadCategoryPage, getStarted, seeMoreFaq, slideCarousel }) => (
  <div>
    <Heroes slideCarousel={slideCarousel} />
    <Categories loadCategoryPage={loadCategoryPage} getStarted={getStarted} />
    <Testimonials getStarted={getStarted} />
    <Fees />
    <GetStarted getStarted={getStarted} />
    <Faq seeMore={seeMoreFaq} />
  </div>
);

Home.propTypes = {
  avatars: PropTypes.objectOf(PropTypes.string),
  loadAvatars: PropTypes.func.isRequired,
  loadCategoryPage: PropTypes.func.isRequired,
  getStarted: PropTypes.func.isRequired,
  seeMoreFaq: PropTypes.func.isRequired,
  selectCard: PropTypes.func.isRequired,
  slideCarousel: PropTypes.func.isRequired,
};

export default CSSModules(Home, styles, {
  allowMultiple: true,
});
