import PropTypes from 'prop-types';
import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './FaqQuestion.less';

const FaqQuestion = ({ firstParagraph, secondParagraph, link }) => (
  <div>
    <p className="dna-text-l" styleName="first-paragraph">
      {firstParagraph}
    </p>
    <p className="dna-text-l">{secondParagraph}</p>
    {link && (
      <p className="dna-text-l dna-text-normal">
        <a
          target="_blank"
          rel="noreferrer noopener"
          href={link.href}
          styleName="link"
        >
          {link.value}
        </a>
      </p>
    )}
  </div>
);

FaqQuestion.propTypes = {
  firstParagraph: PropTypes.string.isRequired,
  secondParagraph: PropTypes.string.isRequired,
  link: PropTypes.shape({
    href: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
};

export default CSSModules(FaqQuestion, styles);
