import PropTypes from 'prop-types';
import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import { Link } from 'react-router-dom';
import Waypoint from 'react-waypoint';
import { TranslateXTransition, RevealTransition } from '../Transitions';
import styles from './Testimonials.less';
import Testimonial from './Testimonial';
import avatarLittle from './images/avatar-little.svg';
import avatarSharon from './images/avatar-sharon.svg';
import avatarMorris from './images/avatar-morris.svg';

const testimonials = [
  {
    image: avatarLittle,
    quote:
      'My involvement with JustGiving has been an all-round wonderful experience! Very grateful for its existence.',
    name: 'R Little',
    amountRaised: 4355,
    totalCountSupporters: 45,
  },
  {
    image: avatarSharon,
    quote:
      "Thank you JustGiving, without you I wouldn't have been able to help my friend.",
    name: 'Sharon Croy',
    amountRaised: 16550,
    totalCountSupporters: 463,
  },
  {
    image: avatarMorris,
    quote: 'What an amazing site! I raised my target in the space of 3 hours!!',
    name: 'Ash Morris',
    amountRaised: 1600,
    totalCountSupporters: 22,
  },
];

class Testimonials extends Component {
  static propTypes = {
    getStarted: PropTypes.func.isRequired,
  };

  static getAnimationDetails(index) {
    return {
      direction: index % 2 === 0 ? 'left' : 'right',
      startOffset: index % 2 === 0 ? -60 : 60,
      transitionDelay: `${index * 200 + 200}ms`,
    };
  }

  state = { animate: false };

  onEnter = () => {
    this.setState({ animate: true });
  };

  render() {
    const { getStarted } = this.props;
    const location = 'home > success stories';

    return (
      <div styleName="container">
        <Waypoint onEnter={this.onEnter} />
        <div className="dna-grid-container">
          <div className="dna-grid-row" styleName="body">
            <h1 className="dna-h1" styleName="title">
              Success stories
            </h1>
            <p className="dna-text-xl" styleName="subtitle">
              People like you are making good things happen on JustGiving every
              day!
            </p>
            <div styleName="content">
              {testimonials.map((testimonial, index) => {
                const animation = Testimonials.getAnimationDetails(index);

                return (
                  <TranslateXTransition
                    key={index}
                    active={this.state.animate}
                    startOffset={animation.startOffset}
                    duration="0.5s"
                    timingFunction="ease-in-out"
                    delay={animation.transitionDelay}
                  >
                    <RevealTransition
                      active={this.state.animate}
                      delay="0.5s"
                      duration="1s"
                    >
                      <Testimonial
                        direction={animation.direction}
                        image={testimonial.image}
                        quote={testimonial.quote}
                        name={testimonial.name}
                        amountRaised={testimonial.amountRaised}
                        targetCurrency="GBP"
                        totalCountSupporters={testimonial.totalCountSupporters}
                      />
                    </RevealTransition>
                  </TranslateXTransition>
                );
              })}
            </div>
            <div styleName="action">
              <Link
                className="dna-button dna-button-ghost-clear"
                to="/create"
                onClick={() => getStarted({ location })}
              >
                Get started
              </Link>
            </div>
          </div>
        </div>
        <Waypoint onEnter={this.onEnter} />
      </div>
    );
  }
}

export default CSSModules(Testimonials, styles);
