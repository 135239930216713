import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import CSSModules from 'react-css-modules';
import classNames from 'classnames/bind';
import * as Icon from '../Icon';
import styles from './Categories.less';

const cx = classNames.bind(styles);

const categories = [
  {
    title: 'Health & medical',
    url:
      'https://www.justgiving.com/crowdfunding/categories/health-and-medical',
    icon: <Icon.Health />,
  },
  {
    title: 'In memory',
    url: 'https://www.justgiving.com/crowdfunding/categories/in-memory',
    icon: <Icon.InMemory />,
  },
  {
    title: 'Children & youth',
    url:
      'https://www.justgiving.com/crowdfunding/categories/children-and-youth',
    icon: <Icon.Children />,
  },
  {
    title: 'Disability',
    url:
      'https://www.justgiving.com/crowdfunding/categories/disability-support',
    icon: <Icon.Disability />,
  },
  {
    title: 'Local community',
    url: 'https://www.justgiving.com/crowdfunding/categories/local-community',
    icon: <Icon.LocalCommunity />,
  },
  {
    title: 'Emergencies',
    url: 'https://www.justgiving.com/crowdfunding/categories/emergencies',
    icon: <Icon.Emergencies />,
  },
  {
    title: 'Animals & pets',
    url: 'https://www.justgiving.com/crowdfunding/categories/animals-and-pets',
    icon: <Icon.Animals />,
  },
  {
    title: 'Sports',
    url: 'https://www.justgiving.com/crowdfunding/categories/sports',
    icon: <Icon.Sports />,
  },
  {
    title: 'International aid',
    url:
      'https://www.justgiving.com/crowdfunding/categories/international-volunteering',
    icon: <Icon.InternationalAid />,
  },
  {
    title: 'Education',
    url:
      'https://www.justgiving.com/crowdfunding/categories/schools-and-education',
    icon: <Icon.Education />,
  },
  {
    title: 'Social welfare',
    url: 'https://www.justgiving.com/crowdfunding/categories/social-welfare',
    icon: <Icon.SocialWelfare />,
  },
  {
    title: 'Art & culture',
    url:
      'https://www.justgiving.com/crowdfunding/categories/creative-arts-and-culture',
    icon: <Icon.Art />,
  },
  {
    title: 'Gardens & environment',
    url:
      'https://www.justgiving.com/crowdfunding/categories/gardens-and-environment',
    icon: <Icon.Gardens />,
  },
  {
    title: 'LGBT+',
    url: 'https://www.justgiving.com/crowdfunding/categories/lgbt',
    icon: <Icon.Lgbt />,
  },
  {
    title: 'Politics',
    url: 'https://www.justgiving.com/crowdfunding/categories/politics',
    icon: <Icon.Politics />,
  },
];

class Categories extends Component {
  static propTypes = {
    loadCategoryPage: PropTypes.func.isRequired,
    getStarted: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isVisible: false,
    };
  }

  showCategories = () => {
    this.setState({ isVisible: true });
  };

  render() {
    const { loadCategoryPage, getStarted } = this.props;
    const { isVisible } = this.state;
    const listClassName = cx('dna-grid-col-l-8', {
      short: !isVisible,
      list: isVisible,
    });
    const location = 'home > a new way to raise funds';

    return (
      <section styleName="container">
        <div className="dna-grid-container">
          <div styleName="body" className="dna-grid-row">
            <div className="dna-grid-col-l-4">
              <h1 className="dna-h1 dna-space-mbm">
                A new way to raise funds on JustGiving
              </h1>
              <div className="dna-space-mbm" styleName="start">
                <p className="dna-space-mbm">
                  Raise money for your own personal cause, a person in need,
                  clubs, schools, and communities.
                </p>
                <Link
                  to="/create"
                  className="dna-button dna-button-brand"
                  onClick={() => getStarted({ location })}
                >
                  Get started
                </Link>
              </div>
            </div>
            <ul className={listClassName}>
              {categories.map((c, i) => (
                <li key={i}>
                  <a
                    href={c.url}
                    onClick={() => loadCategoryPage({ name: c.title })}
                  >
                    <span styleName="icon">{c.icon}</span>
                    <span styleName="title">{c.title}</span>
                  </a>
                </li>
              ))}
            </ul>
            {!isVisible ? (
              <button
                type="button"
                className="dna-button dna-hide-from-l dna-button-ghost"
                onClick={this.showCategories}
              >
                View all categories
              </button>
            ) : null}
          </div>
        </div>
      </section>
    );
  }
}

export default CSSModules(Categories, styles);
