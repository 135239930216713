import PropTypes from 'prop-types';
import React from 'react';

const Children = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="31"
    height="35"
    viewBox="51 0 31 35"
  >
    <path
      fill={color}
      fillRule="evenodd"
      d="M75.9 31.37H56.94c-1.37 0-2.4-1.2-2.4-2.58v-8.9c.7.54 1.9.9 3.03.9h1.9v2.6c0 .1.1.2.23.2h2.73c.13 0 .33-.1.33-.2v-2.6h7.5v2.6c0 .1 0 .2.14.2h2.73c.13 0 .18-.1.18-.2v-2.6h2c1.15 0 2.27-.36 3.2-.9v8.85c0 1.36-1.2 2.57-2.56 2.57m-15.5-24.6H72.4c3.3 0 6.07 2.7 6.07 6v4.5c-.7.96-1.92 1.7-3.2 1.7H73.3v-4.2c0-.12-.04-.24-.17-.24H70.4c-.13 0-.14.1-.14.2v4.2h-7.5v-4.2c0-.12-.2-.24-.33-.24H59.7c-.12 0-.23.1-.23.24v4.2h-1.9c-1.28 0-2.32-.74-3.02-1.7v-4.5c0-3.3 2.6-5.98 5.88-5.98m15.22-2.4V.74c0-.25-.32-.55-.58-.55h-5.3c-.25 0-.45.3-.45.53V3.7h-5.86V.74c0-.26-.15-.56-.4-.56h-5.3c-.26 0-.4.3-.4.56v3.6c-3.52 1.26-5.86 4.55-5.86 8.4V28.8c0 3 2.42 5.37 5.44 5.37H75.9c3.02 0 5.38-2.37 5.38-5.4V12.74c0-3.84-2.35-7.13-5.63-8.4"
    />
  </svg>
);

Children.propTypes = {
  color: PropTypes.string,
};

Children.defaultProps = {
  color: '#999999',
};

export default Children;
