import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import asyncConnect from 'src/helpers/asyncConnect';
import Home from 'src/components/Home/Home';
import {
  loadCategoryPage,
  getStarted,
  seeMoreFaq,
  selectCard,
} from 'src/redux/modules/home/';
import { loadAvatars as getAvatars } from '../redux/modules/avatars';
import { slideCarousel } from '../redux/modules/carousel';
import DeletePageSuccessAlert from '../components/DeletePage/DeletePageSuccessAlert';
import { closeDeletePageSuccessAlert } from '../redux/modules/page/deletePage';
const fetchData = dispatch => dispatch(() => {});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      closeDeletePageSuccessAlert,
      loadCategoryPage,
      getStarted,
      seeMoreFaq,
      selectCard,
      loadAvatars: getAvatars,
      slideCarousel,
      onPageView: () => ({
        type: 'HOME_PAGE_VIEW',
        meta: {
          analytics: {
            event: 'page view',
            subtype: 'home',
          },
        },
      }),
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    avatars: state.avatars.avatars,
    hasPageBeenDeleted: state.page.hasPageBeenDeleted,
  };
}

class HomeView extends Component {
  static propTypes = {
    onPageView: PropTypes.func,
    hasPageBeenDeleted: PropTypes.bool,
    closeDeletePageSuccessAlert: PropTypes.func,
  };

  componentDidMount() {
    this.toggleChromeStartFundraisingButton(false);
    this.props.onPageView();
  }

  componentWillUnmount() {
    this.toggleChromeStartFundraisingButton(true);
  }

  toggleChromeStartFundraisingButton(toggle) {
    if (
      window.jg &&
      window.jg.chrome &&
      window.jg.chrome.toggleStartFundraisingButton
    ) {
      window.jg.chrome.toggleStartFundraisingButton(toggle);
    }
  }

  render() {
    return (
      <div>
        {this.props.hasPageBeenDeleted && (
          <DeletePageSuccessAlert
            onClose={this.props.closeDeletePageSuccessAlert}
          />
        )}
        <Home {...this.props} />
      </div>
    );
  }
}

export default asyncConnect(HomeView, {
  mapStateToProps,
  mapDispatchToProps,
  fetchData,
  id: 'HOME_VIEW',
});
