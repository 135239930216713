import PropTypes from 'prop-types';
import React from 'react';
import Carousel from 'src/components/Carousel/Carousel';
import { RevealTransition } from 'src/components/Transitions';
import Hero from './Hero';
import styles from './Hero.less';

const heroes = [
  {
    statement: 'We raised £16,550 to reunite a&nbsp;family',
    pageName: 'sharon-croy',
    image: 'family',
  },
  {
    statement: 'We raised £13,114 for&nbsp;Ollie',
    pageName: 'peteandjane-gardiner',
    image: 'ollie',
  },
  {
    statement: 'We raised £1,600 to produce our&nbsp;film',
    pageName: 'barefiilm',
    image: 'film',
  },
  {
    statement: 'We raised £1,425 for vet&nbsp;bills',
    pageName: 'lolaslegs',
    image: 'lola',
  },
  {
    statement: 'We raised £2,505 for&nbsp;Derry',
    pageName: 'adam-johnson',
    image: 'derry',
  },
];

const Heroes = ({ slideCarousel }) => (
  <RevealTransition active delay="0.5s" duration="1s">
    <Carousel
      touchable
      disableVerticalTouchMove
      autoRotate={5000}
      styles={styles}
      location="home > hero"
      slideCarousel={slideCarousel}
    >
      {heroes.map((hero, i) => (
        <Hero key={i} {...hero} />
      ))}
    </Carousel>
  </RevealTransition>
);

Heroes.propTypes = {
  slideCarousel: PropTypes.func.isRequired,
};

export default Heroes;
