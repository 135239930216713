import PropTypes from 'prop-types';
import React from 'react';

const Emergencies = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="33"
    height="35"
    viewBox="36 0 33 35"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        id="a"
        d="M15.35 24.3c4.27 0 12.56-.9 12.56-.9V12.6C27.9 5.64 22.3 0 15.38 0 8.42 0 2.8 5.64 2.8 12.6v10.8s6.92.9 12.55.9z"
      />
      <mask id="b" width="25.12" height="24.3" x="0" y="0" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(37 9.942)">
        <rect width="29.81" height="5.4" y="19.56" fill={color} rx="2" />
        <use stroke={color} strokeWidth="6" mask="url(#b)" xlinkHref="#a" />
        <path
          stroke={color}
          strokeWidth="2"
          d="M16.7 6.8c-.33 0 4.08 0 4.76 4.55"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <path
        stroke={color}
        strokeWidth="3"
        d="M63.83 10.5l3.37-3.2m-14.58-.76L52.54 2m-11.37 8.5L37.8 7.3"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

Emergencies.propTypes = {
  color: PropTypes.string,
};

Emergencies.defaultProps = {
  color: '#999999',
};

export default Emergencies;
